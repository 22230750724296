<template>
	<div class="navBar " :class="{ 'shadow': isScrolled }">
		<div class="navBar_w f-x-b-c">
			<div class="box f-x-s-c">
				<div @click="navToHome" class="logo1">
					<img
						class="img"
						src="../image/common/nav_logo.png"
						alt=""
					/>
				</div>
                <div @click="navToHome" class="logo2">
					<img
						class="img"
						src="../image/common/nav_logo2.png"
						alt=""
					/>
				</div>
				<el-tooltip placement="bottom-start" popper-class="tooltip">
					<template #content>
						<div class="address-list f-y-s-s">
							<!-- <span v-if="curCity.value=='310100'" class="title">总部</span> -->
							<div class="row ">
                                <div
                                    v-for="(item,index) in cityList"
                                    :key="item.value" 
                                    class="item-box "
                                    :class="index"
                                    >
                                    <div v-if="index%3!=0" class="line"></div>
                                    <div
                                        class="list-item "
                                        :class="{'cus-active':curCity.value==item.value}"
                                        @click="changeCity(item)"
                                    >
                                        {{item.label}}
                                    </div>
                                    <!-- <div v-if="index%3==1" class="line"></div> -->
                                </div>
								
							</div>
						</div>
					</template>
					<div class="address f-x-c-c">
						<img
							class="img"
							src="../image/common/nav_address.png"
							alt=""
						/>
						<span class="text">{{ curCity.label }}</span>
					</div>
				</el-tooltip>
			</div>
			<div  class="header">
				<div
					:class="
						currentIndex === index || hoverIndex === index
							? 'header-item active'
							: 'header-item'
					"
					v-for="(item, index) in title"
					:key="index"
					@mouseenter="itemMousenter(index)"
					@mouseleave="itemLeave(index)"
					@click="handleTabs(item,index)"
					ref="site"
				>
					{{ item[0] }}
                    <div v-if="currentIndex === index "  ref="sit" :class="title[currentIndex][2]"></div>
                    <transition name="expand"  mode="out-in">
                        <div v-if=" hoverIndex==index"  ref="sit" :class="title[hoverIndex][2]"></div>
                    </transition>
				</div>
				<el-collapse-transition>
					<!-- 二级导航 -->
					<div class="solution" ref="solution" v-show="flag" @mouseenter="flag=true" @mouseleave="flag=false">
						<li
							v-for="(item, index) in list"
							:key="index"
							@click="handleTabs(item)"
						>
							{{ item[0] }}
						</li>
					</div>
				</el-collapse-transition>
				<el-collapse-transition>
					<div class="solution1" ref="solution1" v-show="flag1" @mouseenter="flag1=true" @mouseleave="flag1=false">
						<li
							v-for="(item, index) in list1"
							:key="index"
							@click="handleTabs(item)"
						>
							{{ item[0] }}
						</li>
					</div>
				</el-collapse-transition>

                <el-collapse-transition>
					<div class="solution2" ref="solution2" v-show="flag2" @mouseenter="flag2=true" @mouseleave="flag2=false">
						<li
							v-for="(item, index) in list2"
							:key="index"
							@click="handleTabs(item)"
						>
							{{ item[0] }}
						</li>
					</div>
				</el-collapse-transition>
			</div>

            <div v-if="this.curCity.value!='00'" class="part-phone f-x-c-c">
                <img class="icon" src="../image/index/index_nav_phone.png" alt="">
                <span class="text">{{phoneList[this.curCity.value]}}</span>
            </div>
            <transition name="el-fade-in"  mode="out-in">
                <img
                    v-if="!openStatus"
                    class="btn"
                    @click="handleMenu"
                    src="../image/common/nav_btn_open.png"
                    alt=""
                />
            </transition>
            <transition name="el-fade-in " mode="out-in">
                <img    
                    v-if="openStatus"
                    class="btn"
                    @click="handleMenu"
                    src="../image/common/nav_btn_close.png"
                    alt=""
                />
            </transition>
			
		</div>
		<RightMenu ref="refMenu" @changeOpenStatus="changeOpenStatus"></RightMenu>
	</div>
</template>

<script>
import { scrollIntoView } from 'scroll-js'
import {mapMutations, mapState } from 'vuex'
import {useCityDict} from '@/utils/frequent.js'
import { codeToText, provinceAndCityData} from 'element-china-area-data'
import config from '@/config/index.js'
export default {
	data() {
		return {
            isScrolled: false,
            openStatus:false,
			flag: false,
			flag1: false,
			flag2: false,
			// age: false,
			list: [
				['整体家装', '/decoration/whole'],
				['个性化定制整装', '/decoration/bardian'],
				['局改焕新', '/decoration/part'],
				['软装搭配', '/decoration/collocation'],
			],
			list1: [
				['品牌简介', '/about/group'],
				['企业蓝图', '/about/blueprint'],
				['新闻动态', '/about/news'],
				['门店地址','/about/address'],
			],
            list2: [
				['严选建材', '/build/strict'],
				['创新工艺', '/build/innovate'],
			],
			title: [
				['首页', '/','main1'],
				['装修产品', '/decoration/whole','main3'],
				['实景样板间', '/template','main4'],
				['工艺建材', '/build/strict','main3'],
				// ['设计团队', '/design'],
				['设计师', '/design','main2'],
				['装修案例', '/investor','main3'],
				['关于我们', '/about/group','main3'],
			],
			currentIndex: -1,
			hoverIndex: null,
			decite: 0,
			curCity: {
                value:'00',
                label:'全国',
            },
            addressList:{
                '00':'/',
                '310100':'/sh',
                '410100':'/zz',
            },
            storeDict:[],
            cityList:[],
            cityStoreList:null,
            phoneList:{
                '00':'021-33888835',
                '310100':'021-33888835',
                '410100':'400-7878-000',
            }
		}
	},
    computed:{
        ...mapState(['curCityInfo'])
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
	async mounted() {
        this.setIndex()
        this.setCurTab()
        this.getCityData()
        this.curCity = this.curCityInfo
        
	},
	methods: {
        ...mapMutations(['setCurCity']),
        handleScroll() {
            if (window.scrollY > 0) {
                this.isScrolled = true;
            } else {
                this.isScrolled = false;
            }
        },
        setIndex(){
            if(this.curCityInfo.value=='410100'){
                this.title.splice(0,1,['首页','/zz','main1'])
            }
            if(this.curCityInfo.value=='310100'){
                this.title.splice(0,1,['首页','/sh','main1'])
            }
        },
        navToHome(){
            this.$router.push({
                path: this.title[0][1]
            })
        },
        async getCityData(){

            const cityDict = await useCityDict()
            this.cityList = cityDict.map(item=>{
                if(item.cityCode=='310100'){
                    return {
                        value:item.cityCode,
                        label:'上海'
                    }
                }else{
                    return {
                        value:item.cityCode,
                        label:item.name.replace('市', '')
                    }
                }
            })
            // this.cityList.unshift({
            //     value:'410100',
            //     label:'郑州'
            // })
            this.cityList.unshift({
                value:'00',
                label:'全国'
            })
        },
        setCurTab(){
            const path = this.$route.path
            console.log("path",path);
            switch(path){
                case '/quotation':
                    this.currentIndex = -1
                    break;
                case '/':
                case '/sh':
                case '/zz':
                    console.log("bbb");
                    this.currentIndex = 0
                    break;
                case '/decoration/whole':
                case '/decoration/bardian':
                case '/decoration/part':
                case '/decoration/collocation':
                    this.currentIndex = 1
                    break;
                case '/template':
                case '/template/detail':
                    this.currentIndex = 2
                    break;
                case '/build/strict':
                case '/build/innovate':
                    this.currentIndex = 3
                    break;
                case '/design':
                case '/design/detail':
                    this.currentIndex = 4
                    break;
                case '/investor':
                case '/investor/detail':
                    this.currentIndex = 5
                    break;
                case '/about/group':
                case '/about/blueprint':
                case '/about/news':
                case '/about/news-detail':
                case '/about/address':
                    this.currentIndex = 6
                    break;
            }
            if(this.currentIndex!=-1){
                // console.log("this.$refs.sit",this.$refs.sit);
                

                // this.$nextTick(()=>{
                //     this.$refs.sit[this.currentIndex].className = this.title[this.currentIndex][2]
                //  console.log("this.$refs.sit[this.currentIndex].className",this.$refs.sit[this.currentIndex].className);
                // })
            }
            
            // if (this.currentIndex == 0) {
				
			// } else this.$refs.sit[this.currentIndex].className = 'main2'
            // this.$refs.sit[0].className = 'main'
        },
		changeCity(data) {
            // this.curCity = data
            // this.setCurCity(data)
            // this.$router.push({
            //     path:this.addressList[data.value]
            // })
            const res = config.cityList.find(item=>item.cityCode == data.value)
            console.log("res",res);
            if(res && res.cityCode!=this.curCityInfo.value) window.open(res.indexPath,"_blank");
		},
        changeOpenStatus(){
            this.openStatus = !this.openStatus
        },
		handleMenu() {
            this.openStatus = !this.openStatus
			this.$refs.refMenu.change()
		},
		itemLeave() {
            this.hoverIndex = null
			this.flag = false
			this.flag1 = false
			this.flag2 = false
		},
		itemMousenter(index) {
			this.hoverIndex = index
			if (this.hoverIndex === 1) this.flag = true
			if (this.hoverIndex === 6) this.flag1 = true
            if (this.hoverIndex === 3) this.flag2 = true
		},
		handleTabs(item,index) {
			this.$router.push(item[1])
			// scrollIntoView(document.body, document.body, {
			// 	behavior: 'smooth',
			// }).then(function () {})
            if(index==0 || index) this.currentIndex = index
			
		},
	},
}
</script>

<style lang="less" scoped>
@import '../css/index.css';
@media screen and (max-width: 899px) {
    .shadow {
        box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.1);
    }
    .address-list {
		// height: 189px;
		background: #753cbe;
        .title {
			
			font-weight: 400;
			font-size: 14px;
			color: #ffffff;
            padding-left: 12px;
		}
		.row {
			margin-top: 5px;
			width: 100%;
            flex-wrap: wrap;
            align-items: center;
            display: flex;
            justify-content:flex-start; 
            .item-box:nth-child(n+4){
                margin-top: 10px;
            }
            .item-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 50px;
                width: 33%;
                .cus-active{
                    font-weight: 600 !important;
                }
                .list-item {
                    
                    width: 100%;
                    text-align: center;
                    
                    font-weight: 400;
                    font-size: 14px;
                    color: #ffffff;
                    cursor: pointer;
                }
                .line {
                    width: 1px;
                    height: 10px;
                    background: #956cc9;
                    // margin-right: 25px;
                }
            }
            
		}
	}
	.menu {
		padding: 0 12px;
		margin-top: 43px;
		/deep/.el-collapse-item {
			.el-collapse-item__header {
				height: 45px;
				.box {
					width: 100%;
					height: 45px;
					padding-right: 15px;
					box-sizing: border-box;
					.title {
						
						font-weight: 600;
						font-size: 18px;
						color: #210d3a;
						line-height: 45px;
					}
				}
				.el-collapse-item__arrow {
					display: none;
				}
			}
		}
	}
	.navBar {
		display: flex;
		width: 100%;
		height: 43px;
		flex-wrap: nowrap;
		// padding: 8px 13px;
		padding: 0 13px;
		background: #fff;
		position: fixed;
		box-sizing: border-box;
		z-index: 2040;
        top: 0;
		.navBar_w {
			width: 100%;
			// height: 40px;
			display: flex;
			position: relative;
			// left: 68.9%;
			// transform: translateX(-50%);
		}
        .logo1{
            display: none;
        }
		.logo2 {
			// margin: 20px 217px 22px 33px;
			width: 106px;
			height: 24px;
            
			.img {
				margin: auto;
				display: block;
				width: 106px;
				height: 24px;
			}
		}
		.address {
            width: 49px;
            height: 18px;
            background: #E7E7E7;
            border-radius: 9px;
            margin-left: 9px;
			.img {
				width: 11px;
				height: 11px;
				margin-right: 3px;
                object-fit: cover;
			}
			.text {
				
				font-weight: 400;
				font-size: 11px;
				line-height: 11px;
				color: #2b1546;
			}
		}
		.header {
			display: none;
		}
        .part-phone{
            display: none;
        }
		.btn {
            position: absolute;
            right: 0;
            
			width: 26px;
			height: 24px;
		}
	}
}
@media screen and (min-width: 900px) {
    .box-show {
      height: 100px;
      background-color: #4CAF50;
      width: 100px;
      transform-origin: center; 
    }

    .expand-enter-active, .expand-leave-active {
        transition: transform 0.5s ease-in-out, width 0.5s ease-in-out;
    }

    .expand-enter, .expand-leave-to /* .expand-leave-active in <2.1.8 */ {
        width: 0;
        transform: scaleX(0);
    }
    
	.address-list {
		// height: 189px;
		background: #753cbe;
		
		.title {
			
			font-weight: 400;
			font-size: 24px;
			color: #ffffff;
            padding-left: 30px;
		}
		.row {
			margin-top: 10px;
			width: 100%;
            flex-wrap: wrap;
            align-items: center;
            display: flex;
            justify-content:flex-start; /* 内容水平居中 */
            .item-box:nth-child(n+4){
                margin-top: 20px;
            }
            .item-box{
                display: flex;
                justify-content: space-between;
                align-items: center;
                min-width: 100px;
                width: 33%;
                .cus-active{
                    font-weight: 600 !important;
                }
                .list-item {
                    
                    width: 100%;
                    text-align: center;
                    
                    font-weight: 400;
                    font-size: 24px;
                    color: #ffffff;
                    cursor: pointer;
                }
                .line {
                    width: 1px;
                    height: 20px;
                    background: #956cc9;
                    // margin-right: 25px;
                }
            }
            
		}
	}
    .shadow {
        box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.1);
    }
	.navBar {
		display: flex;
		width: 100%;
		height: 96px;
		flex-wrap: nowrap;
		// padding: 18px 24px 29px 24px;
		padding: 0 24px;
		background: #fff;
		// position: fixed;
		box-sizing: border-box;
		z-index: 999;
        top: 0;
		.navBar_w {
			width: 100%;
			// height: 40px;
			display: flex;
			position: relative;
			// left: 68.9%;
			// transform: translateX(-50%);
		}
		.logo1 {
            cursor: pointer;
			// margin: 20px 217px 22px 33px;
			width: 182px;
			height: 63px;
			display: inline-block;
			margin-right: 38px;
			.img {
				margin: auto;
				display: block;
				width: 182px;
				height: 63px;
			}
		}
        .logo2{
            display: none;
        }
		.address {
			min-width: 128px;
			height: 48px;
			background: #e7e7e7;
			border-radius: 24px;
			padding: 9px 21px;
			box-sizing: border-box;
			cursor: pointer;
			.img {
				width: 30px;
				height: 30px;
				margin-right: 7px;
			}
			.text {
				
				font-weight: 400;
				font-size: 24px;
				line-height: 15px;
				color: #2b1546;
			}
		}

		.header {
			// flex: 1;
			display: flex;
			// box-sizing: border-box;
			height: 96px;
			margin-left: 49px;
			justify-content: end;
			align-items: center;
			position: relative;

			.icon1 {
				position: absolute;
				left: 209px;
				top: 17px;
			}
			.icon2 {
				position: absolute;
				left: 209px;
				top: 17px;
			}
			.icon3 {
				position: absolute;
				right: 13px;
				top: 17px;
			}
			.icon4 {
				position: absolute;
				right: 13px;
				top: 17px;
			}
			.solution {
                z-index: 99;
				position: absolute;
				width: 200px;
				// height: 221px;
				background: #ffffff;
				box-shadow: 0px 12px 20px 0px rgba(4, 0, 0, 0.11);
				border-radius: 0px 0px 10px 10px;
				left: 89px;
				top: 96px;
				cursor: pointer;
                overflow: hidden;
                li:last-child{
                    border-bottom: none;
                }
				li {
					width: 100%;
					box-sizing: border-box;
					height: 55px;
					padding: 0 15px;
					
					font-weight: 300;
					font-size: 22px;
					color: #1b1d22;
					text-align: left;
					line-height: 55px;
                    border-bottom: 1px solid #E5E5E5;
					&:hover {
						// background-color: #2257b0;
						background: #753cbd;
						color: #fff;
					}
				}
			}
			.solution1 {
                z-index: 99;
				position: absolute;
				width: 200px;
				// height: 221px;
				background: #ffffff;
				box-shadow: 0px 12px 20px 0px rgba(4, 0, 0, 0.11);
				border-radius: 0px 0px 10px 10px;
				cursor: pointer;
				right: -25px;
				top: 96px;
                overflow: hidden;
                li:last-child{
                    border-bottom: none;
                }
				li {
					width: 100%;
					box-sizing: border-box;
					height: 55px;
					padding: 0 15px;
					
					font-weight: 300;
					font-size: 22px;
					color: #1b1d22;
					text-align: left;
					line-height: 55px;
                    border-bottom: 1px solid #E5E5E5;
					&:hover {
						// background-color: #2257b0;
						background: #753cbd;
						color: #fff;
					}
				}
			}
            .solution2 {
                z-index: 99;
				position: absolute;
				width: 200px;
				// height: 221px;
				background: #ffffff;
				box-shadow: 0px 12px 20px 0px rgba(4, 0, 0, 0.11);
				border-radius: 0px 0px 10px 10px;
				cursor: pointer;
				left: 415px;
				top: 96px;
                overflow: hidden;
                li:last-child{
                    border-bottom: none;
                }
				li {
                    border-bottom: 1px solid #E5E5E5;
					width: 100%;
					box-sizing: border-box;
					height: 55px;
					padding: 0 15px;
					
					font-weight: 300;
					font-size: 22px;
					color: #1b1d22;
					text-align: left;
					line-height: 55px;
                    box-sizing: border-box;
					&:hover {
						// background-color: #2257b0;
						background: #753cbd;
						color: #fff;
					}
				}
			}
			.header-item {
				//   width: 64px;
				height: 96px;
				font-size: 24px;
				margin: 0 28px;
				display: flex;
				font-weight: 400;
				color: #111111;
				line-height: 96px;
				align-items: center;
				flex-direction: column;
				cursor: pointer;
				z-index: 2;

				.main {
					// display: block;
                    width: 0;
				}
				.main1,.main2,.main3,.main4 {
					
					height: 4px;
					background: #753cbe;
					display: flex;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
					position: absolute;
					// top: 50px;
					top: 92px;
				}
				.main1 {
					width: 32px;
				}
                .main2 {
					width: 60px;
				}
                .main3 {
					width: 80px;
				}
                .main4 {
					width: 105px;
				}
			}
			/deep/.header-item:nth-child(3) {
				//   width: 80px;
			}
			.active {
				cursor: pointer;
				color: #644196;
				text-align: center;
				.solution {
					display: block;
				}
			}
		}
		.btn {
			display: none;
		}
        .part-phone{
            margin-right: -24px;
            width: 445px;
            height: 96px;
            background: #FD8B2F;
            border-radius: 0px 0px 0px 90px;
            padding: 0 20px;
            box-sizing: border-box;
            .icon{
                width: 53px;
                height: 53px;
                object-fit: cover;
                margin-right: 10px;
            }
            .text{
                font-family: DIN;
                font-weight: bold;
                font-size: 45px;
                color: #FFFFFF;
            }
        }
	}
}
</style>
