<template>
	<div class="footer f-y-s-c">
		<div class="info1 f-y-s-c">
			<div class="info-content">
				<div v-if="isBranch" class="content-left-branch">
					<img
						class="img"
						src="../image/common/footer_logo.png"
						alt=""
					/>
					<span class="text1">官方电话</span>
					<span class="text2">{{phoneList[curCity.value]}}</span>
				</div>
                <div v-else class="content-left">
					<img
						class="img"
						src="../image/common/footer_logo.png"
						alt=""
					/>
					<span class="text1">官方电话</span>
					<span class="text2">{{phoneList[curCity.value]}}</span>
					<span class="text3">门店地址</span>
					<span v-for="(item,index) in storeList" :key="index" class="text4">
                        {{ formartAddress(item) }}
                    </span>
				</div>
				<div class="content-right">
					<div class="list-item">
						<span class="title">装修产品</span>
						<span
							v-for="(item, index) in list1"
							:key="index"
							@click="navToPage(item.url)"
							class="text1"
							>{{ item.title }}</span
						>
					</div>
					<div class="list-item">
						<span class="title">案例作品</span>
						<span
							v-for="(item, index) in list2"
							:key="index"
							@click="navToPage(item.url)"
							class="text1"
							>{{ item.title }}</span
						>
					</div>
					<div class="list-item">
						<span class="title">关于我们</span>
						<span
							v-for="(item, index) in list3"
							:key="index"
							@click="navToPage(item.url)"
							class="text1"
							>{{ item.title }}</span
						>
					</div>
					<div class="list-item">
						<span class="title">关注我们</span>
						<div class="f-y-c-c">
							<img
								class="img"
								src="../image/common/footer_qr.png"
								alt=""
							/>
							<span class="text2">官方公众号</span>
						</div>
					</div>
				</div>
			</div>
			<div class="filing f-x-c-c">
				Copyright © 2024 上海境喜家居科技有限公司 沪ICP备17024341号-1
				沪公网安备31011502003683号
			</div>
		</div>
        <div class="info2 f-y-s-c">
            <div class="info-content">
				<div class="content-top">
					<img
						class="img"
						src="../image/common/footer_logo.png"
						alt=""
					/>
                    <div class="cus-line"></div>
					<span class="text1">服务热线电话</span>
					<span class="text2" @click="callPhone">{{phoneList[curCity.value]}}</span>
                    <div class="qr-code f-x-s-c">
                        <img
                            class="img"
                            src="../image/common/footer_qr.png"
                            alt=""
                        />
						<div class="f-y-c-s">
						    <span class="text3">关注我们</span>
							<span class="text4">了解最新家装资讯</span>
							<span class="text4">获取装修案例</span>
						</div>
					</div>
				</div>
				<div class="content-bottom">
					<div class="list-item">
						<span class="title">装修产品</span>
                        <div class="box f-x-s-c">
                            <span
                                v-for="(item, index) in list1"
                                :key="index"
                                @click="navToPage(item.url)"
                                class="text1"
                                >{{ item.title }}</span
                            >
                        </div>
						
					</div>
					<div class="list-item">
						<span class="title">案例作品</span>
                        <div class="box f-x-s-c">
                            <span
                                v-for="(item, index) in list2"
                                :key="index"
                                @click="navToPage(item.url)"
                                class="text1"
                                >{{ item.title }}</span
                            >
                        </div>
						
					</div>
					<div class="list-item">
						<span class="title">装修产品</span>
						<div class="box f-x-s-c">
                            <span
                                v-for="(item, index) in list3"
                                :key="index"
                                @click="navToPage(item.url)"
                                class="text1"
                                >{{ item.title }}</span
                            >
                        </div>
					</div>
					
				</div>
			</div>
			<div class="filing f-x-c-c">
                Copyright ©&nbsp;&nbsp;2024&nbsp;&nbsp;&nbsp;&nbsp;上海境喜家居科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;沪ICP备17024341号-1
			</div>
		</div>
	</div>
</template>
<script>
import {useSalesRoomDict} from '@/utils/frequent.js'
import {codeToText} from 'element-china-area-data'
import {mapMutations, mapState } from 'vuex'
export default {
	data() {
		return {
            storeList:[],
			list1: [
				{
					title: '整体家装',
					url: '/decoration/whole',
				},
				{
					title: '个性化整装',
					url: '/decoration/bardian',
				},
				{
					title: '软装搭配',
					url: '/decoration/collocation',
				},
				{
					title: '局改焕新',
					url: '/decoration/part',
				},
			],
			list2: [
				{
					title: '实景样板间',
					url: '/template',
				},
				{
					title: '装修案例',
					url: '/investor',
				},
			],
			list3: [
				{
					title: '新闻动态',
					url: '/about/news',
				},
				{
					title: '品牌简介',
					url: '/about/group',
				},
				{
					title: '企业蓝图',
					url: '/about/blueprint',
				},
				{
					title: '门店地址',
					url: '/about/address',
				},
			],
            isBranch:true,
            phoneList:{
                '00':'021-33888835',
                '310100':'021-33888835',
                '410100':'400-7878-000',
            },
            curCity:{}
		}
	},
    computed:{
        ...mapState(['curCityInfo'])
    },
	async mounted() {
        this.storeList = await useSalesRoomDict()
        this.isBranch = (this.curCityInfo.value!='00')
        this.curCity = this.curCityInfo
    },
	methods: {
        callPhone(){
            window.location.href = `tel:${this.phoneList[this.curCity.value]}`;
        },
        formartAddress(data){
            let str = data.showRoomName+'：'
            if(data.province) str = str + codeToText[data.province.slice(0,2)]
            if(data.city) str = str + codeToText[data.city.slice(0,4)]
            if(data.region) str = str + codeToText[data.region]
            if(data.address) str = str + data.address
            return str
        },
		navToPage(url) {
			this.$router.push(url)
		},
	},
}
</script>
<style lang="less" scoped>
@media screen and (max-width: 899px) {
	.footer {
		width: 100%;
        .info1{
            display: none;
        }
		.info2 {
			width: 100%;
			height: 520px;
			background: #190039;
            .info-content{
                height: 470px;
				width: 100%;
				display: flex;
				padding: 17px 13px;
				box-sizing: border-box;
				justify-content: space-between;
				align-items: flex-start;
                flex-flow: column;
				.content-top {
					display: flex;
					flex-flow: column;
					align-content: flex-start;
					justify-content: center;
                    width: 100%;
					.img {
						width: 123px;
                        height: 42px;
					}
                    .cus-line{
                        width: 100%;
                        height: 1px;
                        background: #E5E5E5;
                        opacity: 0.5;
                        margin-top: 15px;
                    }
					.text1 {
						
						font-weight: 500;
						font-size: 15px;
						color: #ffffff;
						line-height: 15px;
						opacity: 0.9;
						margin-top: 15px;
					}
					.text2 {
						font-family: DIN;
						font-weight: bold;
						font-size: 24px;
						color: #ffffff;
						line-height: 24px;
						margin-top: 6px;
					}
                    .qr-code{
                        margin-top: 20px;
                        .img{
                            width: 105px;
                            height: 105px;
                            margin-right: 15px;
                        }
                        .text3 {
                            
                            font-weight: bold;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 14px;
                            margin-bottom: 12px;
                        }
                        .text4 {
                            
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF;
                            line-height: 14px;
                            opacity: 0.8;
                            margin-bottom: 7px;
                        }
                        
                    }
					
				}
				.content-bottom {
					display: flex;
                    flex-flow: column;
                    margin-top: 23px;
                    width: 100%;
					.list-item {
						display: flex;
						flex-flow: column;
						margin-bottom: 23px;
                        width: 100%;
                        .title {
                            
                            font-weight: bold;
                            font-size: 14px;
                            color: #ffffff;
                            line-height: 14px;
                            margin-bottom: 11px;
                        }
                        .box{
                            .text1 {
                                
                                font-weight: 400;
                                font-size: 14px;
                                color: #ffffff;
                                line-height: 14px;
                                opacity: 0.8;
                                margin-right: 20px;
                                cursor: pointer;
                            }
                        }
						
					}
				}
            }
			.filing {
				height: 50px;
				width: 100%;
				background: #190039;
				border-top: 1px solid #ffffff;
				opacity: 0.6;
				box-sizing: border-box;
				
				font-weight: 400;
				font-size: 10px;
				color: #ffffff;
			}
		}
	}
}
@media screen and (min-width: 900px) {
	.footer {
		width: 100%;
        .info2{
            display: none;
        }
		.info1 {
			width: 100%;
			// height: 655px;
			background: #190039;
			.info-content {
				height: 555px;
				width: 100%;
				display: flex;
				padding: 88px 186px;
				box-sizing: border-box;
				justify-content: space-between;
				align-items: flex-start;
                .content-left-branch{
                    display: flex;
					flex-flow: column;
					align-content: flex-start;
					justify-content: center;
					.img {
						// width: 400px;
						height: 150px;
                        object-fit: cover;
					}
					.text1 {
						
						font-weight: 500;
						font-size: 28px;
						color: #ffffff;
						line-height: 28px;
						opacity: 0.8;
						margin-top: 28px;
					}
					.text2 {
						font-family: DIN;
						font-weight: bold;
						font-size: 58px;
						color: #ffffff;
						line-height: 58px;
						margin-top: 20px;
					}
                }
				.content-left {
					display: flex;
					flex-flow: column;
					align-content: flex-start;
					justify-content: center;
					.img {
						width: 257px;
						height: 89px;
					}
					.text1 {
						
						font-weight: 500;
						font-size: 28px;
						color: #ffffff;
						line-height: 28px;
						opacity: 0.8;
						margin-top: 28px;
					}
					.text2 {
						font-family: DIN;
						font-weight: bold;
						font-size: 48px;
						color: #ffffff;
						line-height: 48px;
						margin-top: 15px;
					}
					.text3 {
						
						font-weight: 500;
						font-size: 28px;
						color: #ffffff;
						line-height: 28px;
						opacity: 0.8;
						margin-top: 32px;
						margin-bottom: 23px;
					}
					.text4 {
						
						font-weight: 400;
						font-size: 16px;
						color: #ffffff;
						line-height: 16px;
						margin-bottom: 10px;
					}
				}
				.content-right {
					display: flex;
					.list-item {
						display: flex;
						flex-flow: column;
						margin-left: 90px;
						.title {
							
							font-weight: bold;
							font-size: 28px;
							color: #ffffff;
							line-height: 28px;
							margin-bottom: 37px;
						}
						.text1 {
							
							font-weight: 400;
							font-size: 26px;
							color: #ffffff;
							line-height: 26px;
							opacity: 0.8;
							margin-bottom: 25px;
							cursor: pointer;
						}
                        .text1:hover{
                            opacity: 1;
                        }
						.text2 {
							
							font-weight: 400;
							font-size: 24px;
							color: #ffffff;
							line-height: 24px;
							opacity: 0.8;
							margin-top: 18px;
						}
					}
				}
			}
			.filing {
				height: 80px;
				width: 100%;
				background: #190039;
				border-top: 1px solid #a399b0;
				opacity: 0.6;
				box-sizing: border-box;
				
				font-weight: 400;
				font-size: 18px;
				color: #ffffff;
			}
		}
	}
}
</style>
